import { setItemStorage, getItemStorage, removeItemStorage } from "@/core/helpers/storage";

class AuthService {
    /**
     * get user detail
     */
    public static getUserDetail(): any {
        const userDetail: any = getItemStorage("usr");
        return userDetail;
    }

    /**
     * get user features
     */
    public static getUserFeatures(): any {
        const userDetail: any = getItemStorage("usr");
        const user_features = userDetail?.features;

        return user_features;
    }

    /**
     * get selected user property value
     */
    public static getUserProperty(): any {
        const userProperty: any = getItemStorage("usr_prop");
        return userProperty;
    }

    /**
     * set selected user propperty value
     */
    public static setUserProperty(value: any): void {
        setItemStorage('usr_prop', value);
    }

    /**
     * check if user can access the feature
     */
    public static isUserFeature(feature_name: any, meta_name: any = ""): boolean {
        let is_feature = false;
        const userDetail: any = getItemStorage("usr");
        if (userDetail) {
            const user_features = userDetail.features;
            const have_feature_name = user_features ? user_features[feature_name] : null;
            if (have_feature_name) {
                if (meta_name) {
                    const have_feature_meta = have_feature_name.includes(meta_name);
                    is_feature = have_feature_meta;
                } else {
                    is_feature = !!have_feature_name;
                }
            }
        }

        return is_feature;
    }
}

export default AuthService;