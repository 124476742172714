import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { ElNotification } from 'element-plus';
import store from "@/store";
import Router from '@/router';
import { Actions } from "@/store/enums/StoreEnums";
import { tricklingProgress } from '@/core/plugins/trickling-progress';


/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    // ApiService.vueInstance.axios.defaults.baseURL = import.meta.env.VITE_API_URL;
    // ApiService.vueInstance.axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
    // ApiService.vueInstance.axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET, POST, PUT, DELETE";
    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        tricklingProgress.start();
        return config;
      }
    );
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        tricklingProgress.done();
        return response;
      },
      (error: AxiosError) => {
        tricklingProgress.done();
        const status = error.response?.status || 500;
        const statusText = error.response?.statusText || "";
        const errorData = error.response?.data as any || null;
        // auto redirect if unauthorized
        // && !error.request.ResponseURL.startsWith(import.meta.env.VITE_API_URL)
        if ([401, 419].includes(status)) {
          // Logging out
          store.dispatch(Actions.LOGOUT);
          // redirect to login
          Router.push({ name: 'sign-in' });
          // set notification
          ElNotification.info({
            title: 'Info',
            message: 'You have logged out'
          });
        } else if ([500].includes(status)) {
          // redirect to error 500
          Router.push({ name: '500' });
        } else {
          // set notification
          // ElNotification.error({
          //   title: 'Error',
          //   message: `(${status}) ${errorData.message || error.message}`
          // });
          const errorResult = {
            data: errorData,
            status: status,
            statusText: statusText,
          }
          return Promise.reject(errorResult); // Delegate error to calling side
        }
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["X-CSRF-TOKEN"] =
      document.querySelector("meta[name='csrf-token']")!.getAttribute("content");
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    // slug = "" as string,
    params?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: data
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params?: any | null,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: data
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params?: any | null,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params, config);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: data
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params?: any | null,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params, config);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
