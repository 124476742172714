
import { createTrickling } from 'trickling';

// Create a Trickling progress instance
const tricklingProgress = createTrickling({
    // Options
    color: '#009ef7',
    progressBarHeight: '3px',
    spinnerStrokeWidth: '3px',
    zIndex: 2999,
});

// You can also change options after creating a Trickling progress instance
tricklingProgress.setOptions({
    // ...
});

// Export the instance
export { tricklingProgress }