import { EncryptStorage } from 'encrypt-storage';
import { encrypt, decrypt } from "@/core/helpers/encryption";

// initialize
const encryptLocalStorage = new EncryptStorage(import.meta.env.VITE_SECRET_PASSPHRASE, {
    prefix: '@instanceL',
    storageType: "localStorage"
});

const encryptSessionStorage = new EncryptStorage(import.meta.env.VITE_SECRET_PASSPHRASE, {
    prefix: '@instanceS',
    storageType: "sessionStorage"
});

/**
 * set item storage
 */
export const setItemStorage = ((label: string, value: any, decrypted: boolean = false, storageType: string = 'localStorage'): void => {
    if (storageType === "localStorage") {
        encryptLocalStorage.setItem(label, value, decrypted);
    } else if (storageType === "sessionStorage") {
        encryptSessionStorage.setItem(label, value, decrypted);
    }
    // window.localStorage.setItem(label, encrypt(value));
});

/**
 * get item storage
 */
export const getItemStorage = ((label: string, decrypted: boolean = false, storageType: string = 'localStorage') => {
    let get_item = undefined;
    if (storageType === "localStorage") {
        get_item = encryptLocalStorage.getItem<any>(label, decrypted);
    } else if (storageType === "sessionStorage") {
        get_item = encryptSessionStorage.getItem<any>(label, decrypted);
    }
    // const get_item = window.localStorage.getItem(label);
    // return get_item ? decrypt(get_item) : null;
    return get_item || null;
});

/**
 * remove item storage
 */
export const removeItemStorage = ((label: string, storageType: string = 'localStorage'): void => {
    if (storageType === "localStorage") {
        encryptLocalStorage.removeItem(label);
    } else if (storageType === "sessionStorage") {
        encryptSessionStorage.removeItem(label);
    }
    // window.localStorage.removeItem(label);
});

export default { setItemStorage, getItemStorage, removeItemStorage };

/**
 ** Hints:
 * usr => data user
 * usr_prop => user property value
 * config => template configuration
 * id_token => jwt token
 */