import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setItemStorage, getItemStorage, removeItemStorage } from "@/core/helpers/storage";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { route } from '~ziggy-js';

export interface User {
  id: any;
  name: string;
  features: any;
  email: string;
  password: string;
  token: string;
  google2fa_is_active: boolean;
  is_active: boolean;
  properties: any;
  user_categories: any;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
 * Get user detail
 * @returns objects
 */
  get getUserDetail() {
    const userDetail: any = getItemStorage("usr");
    return userDetail;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data;
    this.errors = {};
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    removeItemStorage('usr');
    removeItemStorage('usr_prop');
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post(route('auth.login'), credentials) // '/auth/gettoken'
      .then((response) => {
        // console.log(data);
        this.context.commit(Mutations.SET_AUTH, response.data);
        setItemStorage('usr', response.data.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.AUTH_LOGIN](user) {
    this.context.commit(Mutations.SET_AUTH, user);
    setItemStorage('usr', user.data);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR, error?.data);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(route('auth.verifytoken')) // '/auth/checkvalidtoken', payload
        .then((response) => {
          const resp = response.data;
          setItemStorage('usr', resp.data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error?.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
