import { setItemStorage, getItemStorage, removeItemStorage } from "@/core/helpers/storage";
import { encrypt, decrypt } from "@/core/helpers/encryption";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  const get_token = getItemStorage(ID_TOKEN_KEY);
  return get_token;
  // const get_token = window.localStorage.getItem(ID_TOKEN_KEY);
  // return get_token ? decrypt(get_token) : null;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  setItemStorage(ID_TOKEN_KEY, token);
  // window.localStorage.setItem(ID_TOKEN_KEY, encrypt(token));
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  removeItemStorage(ID_TOKEN_KEY);
  // window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
