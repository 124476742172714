import App from "./App.vue";
// import './bootstrap';
// import '../sass/style.scss';
import Router from '@/router';
import store from '@/store';
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";

import { createApp } from 'vue';
import { ZiggyVue } from '~ziggy-js';

import * as Popper from '@popperjs/core';
import * as bootstrap from '~bootstrap';

window.Popper = Popper;
window.bootstrap = bootstrap;

const app = createApp(App);

app.use(Router);
app.use(store);

app.use(ZiggyVue);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');